
export const environment= {
  production: true,
  config: {
    apiURL: 'https://qsr-prod-api-ng.ukwest.cloudapp.azure.com',
    scanningStudioUrl: 'https://scanning.gulndt.com',

    msalConfig: {
      clientId: 'a3b6ed0e-bb4e-46b2-848e-11a751f4720d',
      authority: 'https://gulndt.b2clogin.com/gulndt.onmicrosoft.com/B2C_1_SignIn_QSR',
      knownAuthorities: ['gulndt.b2clogin.com'],
      redirectUri: 'https://admin-scanning.gulndt.com',
      postLogoutRedirectUri: 'https://admin-scanning.gulndt.com',
      scopes: ['https://gulndt.onmicrosoft.com/api/api.read'],
      Graph_endpoint: 'https://graph.microsoft.com/v1.0/me',
    },
    blobConfig: {
      dataContainer: 'data',
      avatarContainer: 'avatars'
    }
  }
};
